/* eslint-disable */
// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies,ts_nocheck,eslint_disable
// @generated from protobuf file "verily-me/web/bundle/bff/api/v1/bundle_service.proto" (package "verily_me.web.bundle.bff.api.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message verily_me.web.bundle.bff.api.v1.BatchGetBundlesRequest
 */
export interface BatchGetBundlesRequest {
    /**
     * @generated from protobuf field: repeated string bundle_names = 1;
     */
    bundleNames: string[];
}
/**
 * @generated from protobuf message verily_me.web.bundle.bff.api.v1.GetBundleRequest
 */
export interface GetBundleRequest {
    /**
     * Identifier for bundle. Formatted as bundles/{bundle_id} where {bundle_id} = RequestGroup.id.
     *
     * @generated from protobuf field: string bundle_name = 1;
     */
    bundleName: string;
}
/**
 * @generated from protobuf message verily_me.web.bundle.bff.api.v1.ListBundlesRequest
 */
export interface ListBundlesRequest {
}
/**
 * @generated from protobuf message verily_me.web.bundle.bff.api.v1.UpdateBundleStepsRequest
 */
export interface UpdateBundleStepsRequest {
    /**
     * Identifier for bundle. Formatted as bundles/{bundle_id} where {bundle_id} = RequestGroup.id.
     * Needed to be able to complete the bundle if given step is last submittable.
     *
     * @generated from protobuf field: string bundle_name = 2;
     */
    bundleName: string;
    /**
     * The bundle steps to be updated in Action service.
     *
     * @generated from protobuf field: repeated verily_me.web.bundle.bff.api.v1.BundleStep bundle_steps = 1;
     */
    bundleSteps: BundleStep[];
}
/**
 * @generated from protobuf message verily_me.web.bundle.bff.api.v1.BatchGetBundlesResponse
 */
export interface BatchGetBundlesResponse {
    /**
     * @generated from protobuf field: repeated verily_me.web.bundle.bff.api.v1.Bundle bundles = 1;
     */
    bundles: Bundle[];
}
/**
 * @generated from protobuf message verily_me.web.bundle.bff.api.v1.ListBundlesResponse
 */
export interface ListBundlesResponse {
    /**
     * @generated from protobuf field: repeated verily_me.web.bundle.bff.api.v1.Bundle bundles = 1;
     */
    bundles: Bundle[];
}
/**
 * @generated from protobuf message verily_me.web.bundle.bff.api.v1.UpdateBundleStepsResponse
 */
export interface UpdateBundleStepsResponse {
}
/**
 * @generated from protobuf message verily_me.web.bundle.bff.api.v1.Bundle
 */
export interface Bundle {
    /**
     * Identifier for bundle. Formatted as bundles/{bundle_id} where {bundle_id} = RequestGroup.id.
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * Static steps for the bundle. A series of screens to display once the card is clicked.
     *
     * @generated from protobuf field: repeated verily_me.web.bundle.bff.api.v1.BundleStep steps = 2;
     */
    steps: BundleStep[];
    /**
     * Bundle plan ID, different for each bundle, but the same across users (unlike bundle_name)
     *
     * @generated from protobuf field: string bundle_plan_id = 3;
     */
    bundlePlanId: string;
    /**
     * Bundle plan name, accompanies bundle_plan_id
     *
     * @generated from protobuf field: string bundle_plan_name = 4;
     */
    bundlePlanName: string;
    /**
     * URL to redirect to on finishing the bundle, if empty string, redirect to Home page
     *
     * @generated from protobuf field: string redirect_url_on_completion = 5;
     */
    redirectUrlOnCompletion: string;
}
/**
 * @generated from protobuf message verily_me.web.bundle.bff.api.v1.BundleStep
 */
export interface BundleStep {
    /**
     * Identifier for bundle step. Formatted as surveys/{survey}/versions/{version} or consent_records/{consent_record_id}.
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * Type of content for this step.
     *
     * @generated from protobuf field: verily_me.web.bundle.bff.api.v1.StepType type = 2;
     */
    type: StepType;
    /**
     * The status of the bundle step.
     *
     * @generated from protobuf field: verily_me.web.bundle.bff.api.v1.StepStatus status = 3;
     */
    status: StepStatus;
    /**
     * Whether or not the step is the last submittable step in its bundle.
     *
     * @generated from protobuf field: bool last_submittable = 4;
     */
    lastSubmittable: boolean;
    /**
     * Identifier for the action associated with the bundle step. This is needed to call the action service endpoint
     * for fetching and updating the action. Format: actions/{resource_id}/resource_types/{resource_type}
     * {resource_id} = Id of the Task resource associated with the content/survey/consent tasks
     * {resource_type} = Request ResourceType (should always be a Task in case of bundle step)
     *
     * @generated from protobuf field: string action_name = 5;
     */
    actionName: string;
    /**
     * @generated from protobuf oneof: static_content
     */
    staticContent: {
        oneofKind: "infoStaticContent";
        /**
         * @generated from protobuf field: verily_me.web.bundle.bff.api.v1.InfoStaticContent info_static_content = 6;
         */
        infoStaticContent: InfoStaticContent;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message verily_me.web.bundle.bff.api.v1.InfoStaticContent
 */
export interface InfoStaticContent {
    /**
     * String for 'Next' button, could be 'Start', 'Next' or 'Done'.
     *
     * @generated from protobuf field: string next_button_text = 1;
     */
    nextButtonText: string;
    /**
     * String for 'Previous' button, likely only heard for a11y.
     *
     * @generated from protobuf field: string previous_button_text = 2;
     */
    previousButtonText: string;
    /**
     * String for 'Close' button, likely only heard for a11y.
     *
     * @generated from protobuf field: string close_button_text = 3;
     */
    closeButtonText: string;
    /**
     * State indicating whether to show the previous button and it's enabled.
     *
     * @generated from protobuf field: verily_me.web.bundle.bff.api.v1.ButtonState previous_button_state = 4;
     */
    previousButtonState: ButtonState;
}
/**
 * Maps to the FHIR R4 Task states
 *
 * @generated from protobuf enum verily_me.web.bundle.bff.api.v1.StepStatus
 */
export enum StepStatus {
    /**
     * @generated from protobuf enum value: STEP_STATUS_UNSPECIFIED = 0;
     */
    UNSPECIFIED = 0,
    /**
     * @generated from protobuf enum value: STEP_STATUS_DRAFT = 1;
     */
    DRAFT = 1,
    /**
     * @generated from protobuf enum value: STEP_STATUS_REQUESTED = 2;
     */
    REQUESTED = 2,
    /**
     * @generated from protobuf enum value: STEP_STATUS_RECEIVED = 3;
     */
    RECEIVED = 3,
    /**
     * @generated from protobuf enum value: STEP_STATUS_ACCEPTED = 4;
     */
    ACCEPTED = 4,
    /**
     * @generated from protobuf enum value: STEP_STATUS_READY = 5;
     */
    READY = 5,
    /**
     * @generated from protobuf enum value: STEP_STATUS_ON_HOLD = 6;
     */
    ON_HOLD = 6,
    /**
     * @generated from protobuf enum value: STEP_STATUS_IN_PROGRESS = 7;
     */
    IN_PROGRESS = 7,
    /**
     * @generated from protobuf enum value: STEP_STATUS_CANCELED = 8;
     */
    CANCELED = 8,
    /**
     * @generated from protobuf enum value: STEP_STATUS_REJECTED = 9;
     */
    REJECTED = 9,
    /**
     * @generated from protobuf enum value: STEP_STATUS_FAILED = 10;
     */
    FAILED = 10,
    /**
     * @generated from protobuf enum value: STEP_STATUS_COMPLETED = 11;
     */
    COMPLETED = 11
}
/**
 * @generated from protobuf enum verily_me.web.bundle.bff.api.v1.StepType
 */
export enum StepType {
    /**
     * @generated from protobuf enum value: STEP_TYPE_UNSPECIFIED = 0;
     */
    UNSPECIFIED = 0,
    /**
     * @generated from protobuf enum value: STEP_TYPE_SURVEY = 1;
     */
    SURVEY = 1,
    /**
     * @generated from protobuf enum value: STEP_TYPE_CONSENT = 2;
     */
    CONSENT = 2,
    /**
     * @generated from protobuf enum value: STEP_TYPE_CONTENT = 3;
     */
    CONTENT = 3,
    /**
     * @generated from protobuf enum value: STEP_TYPE_ID_VERIFICATION = 4;
     */
    ID_VERIFICATION = 4,
    /**
     * @generated from protobuf enum value: STEP_TYPE_ENROLLMENT = 5;
     */
    ENROLLMENT = 5
}
/**
 * Status of a given button in app.
 *
 * @generated from protobuf enum verily_me.web.bundle.bff.api.v1.ButtonState
 */
export enum ButtonState {
    /**
     * @generated from protobuf enum value: BUTTON_STATE_UNSPECIFIED = 0;
     */
    BUTTON_STATE_UNSPECIFIED = 0,
    /**
     * @generated from protobuf enum value: HIDDEN = 1;
     */
    HIDDEN = 1,
    /**
     * @generated from protobuf enum value: VISIBLE_AND_DISABLED = 2;
     */
    VISIBLE_AND_DISABLED = 2,
    /**
     * @generated from protobuf enum value: VISIBLE_AND_ENABLED = 3;
     */
    VISIBLE_AND_ENABLED = 3
}
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetBundlesRequest$Type extends MessageType<BatchGetBundlesRequest> {
    constructor() {
        super("verily_me.web.bundle.bff.api.v1.BatchGetBundlesRequest", [
            { no: 1, name: "bundle_names", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["REQUIRED"] } }
        ]);
    }
    create(value?: PartialMessage<BatchGetBundlesRequest>): BatchGetBundlesRequest {
        const message = { bundleNames: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BatchGetBundlesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchGetBundlesRequest): BatchGetBundlesRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string bundle_names */ 1:
                    message.bundleNames.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BatchGetBundlesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string bundle_names = 1; */
        for (let i = 0; i < message.bundleNames.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.bundleNames[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message verily_me.web.bundle.bff.api.v1.BatchGetBundlesRequest
 */
export const BatchGetBundlesRequest = new BatchGetBundlesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBundleRequest$Type extends MessageType<GetBundleRequest> {
    constructor() {
        super("verily_me.web.bundle.bff.api.v1.GetBundleRequest", [
            { no: 1, name: "bundle_name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["REQUIRED"] } }
        ]);
    }
    create(value?: PartialMessage<GetBundleRequest>): GetBundleRequest {
        const message = { bundleName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetBundleRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetBundleRequest): GetBundleRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string bundle_name */ 1:
                    message.bundleName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetBundleRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string bundle_name = 1; */
        if (message.bundleName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.bundleName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message verily_me.web.bundle.bff.api.v1.GetBundleRequest
 */
export const GetBundleRequest = new GetBundleRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListBundlesRequest$Type extends MessageType<ListBundlesRequest> {
    constructor() {
        super("verily_me.web.bundle.bff.api.v1.ListBundlesRequest", []);
    }
    create(value?: PartialMessage<ListBundlesRequest>): ListBundlesRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListBundlesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListBundlesRequest): ListBundlesRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ListBundlesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message verily_me.web.bundle.bff.api.v1.ListBundlesRequest
 */
export const ListBundlesRequest = new ListBundlesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateBundleStepsRequest$Type extends MessageType<UpdateBundleStepsRequest> {
    constructor() {
        super("verily_me.web.bundle.bff.api.v1.UpdateBundleStepsRequest", [
            { no: 2, name: "bundle_name", kind: "scalar", T: 9 /*ScalarType.STRING*/, options: { "google.api.field_behavior": ["REQUIRED"] } },
            { no: 1, name: "bundle_steps", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => BundleStep, options: { "google.api.field_behavior": ["REQUIRED"] } }
        ]);
    }
    create(value?: PartialMessage<UpdateBundleStepsRequest>): UpdateBundleStepsRequest {
        const message = { bundleName: "", bundleSteps: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateBundleStepsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateBundleStepsRequest): UpdateBundleStepsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string bundle_name */ 2:
                    message.bundleName = reader.string();
                    break;
                case /* repeated verily_me.web.bundle.bff.api.v1.BundleStep bundle_steps */ 1:
                    message.bundleSteps.push(BundleStep.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateBundleStepsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string bundle_name = 2; */
        if (message.bundleName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.bundleName);
        /* repeated verily_me.web.bundle.bff.api.v1.BundleStep bundle_steps = 1; */
        for (let i = 0; i < message.bundleSteps.length; i++)
            BundleStep.internalBinaryWrite(message.bundleSteps[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message verily_me.web.bundle.bff.api.v1.UpdateBundleStepsRequest
 */
export const UpdateBundleStepsRequest = new UpdateBundleStepsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BatchGetBundlesResponse$Type extends MessageType<BatchGetBundlesResponse> {
    constructor() {
        super("verily_me.web.bundle.bff.api.v1.BatchGetBundlesResponse", [
            { no: 1, name: "bundles", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Bundle }
        ]);
    }
    create(value?: PartialMessage<BatchGetBundlesResponse>): BatchGetBundlesResponse {
        const message = { bundles: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BatchGetBundlesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BatchGetBundlesResponse): BatchGetBundlesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated verily_me.web.bundle.bff.api.v1.Bundle bundles */ 1:
                    message.bundles.push(Bundle.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BatchGetBundlesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated verily_me.web.bundle.bff.api.v1.Bundle bundles = 1; */
        for (let i = 0; i < message.bundles.length; i++)
            Bundle.internalBinaryWrite(message.bundles[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message verily_me.web.bundle.bff.api.v1.BatchGetBundlesResponse
 */
export const BatchGetBundlesResponse = new BatchGetBundlesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListBundlesResponse$Type extends MessageType<ListBundlesResponse> {
    constructor() {
        super("verily_me.web.bundle.bff.api.v1.ListBundlesResponse", [
            { no: 1, name: "bundles", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Bundle }
        ]);
    }
    create(value?: PartialMessage<ListBundlesResponse>): ListBundlesResponse {
        const message = { bundles: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListBundlesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListBundlesResponse): ListBundlesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated verily_me.web.bundle.bff.api.v1.Bundle bundles */ 1:
                    message.bundles.push(Bundle.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListBundlesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated verily_me.web.bundle.bff.api.v1.Bundle bundles = 1; */
        for (let i = 0; i < message.bundles.length; i++)
            Bundle.internalBinaryWrite(message.bundles[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message verily_me.web.bundle.bff.api.v1.ListBundlesResponse
 */
export const ListBundlesResponse = new ListBundlesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateBundleStepsResponse$Type extends MessageType<UpdateBundleStepsResponse> {
    constructor() {
        super("verily_me.web.bundle.bff.api.v1.UpdateBundleStepsResponse", []);
    }
    create(value?: PartialMessage<UpdateBundleStepsResponse>): UpdateBundleStepsResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateBundleStepsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateBundleStepsResponse): UpdateBundleStepsResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateBundleStepsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message verily_me.web.bundle.bff.api.v1.UpdateBundleStepsResponse
 */
export const UpdateBundleStepsResponse = new UpdateBundleStepsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Bundle$Type extends MessageType<Bundle> {
    constructor() {
        super("verily_me.web.bundle.bff.api.v1.Bundle", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "steps", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => BundleStep },
            { no: 3, name: "bundle_plan_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "bundle_plan_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "redirect_url_on_completion", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Bundle>): Bundle {
        const message = { name: "", steps: [], bundlePlanId: "", bundlePlanName: "", redirectUrlOnCompletion: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Bundle>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Bundle): Bundle {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* repeated verily_me.web.bundle.bff.api.v1.BundleStep steps */ 2:
                    message.steps.push(BundleStep.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string bundle_plan_id */ 3:
                    message.bundlePlanId = reader.string();
                    break;
                case /* string bundle_plan_name */ 4:
                    message.bundlePlanName = reader.string();
                    break;
                case /* string redirect_url_on_completion */ 5:
                    message.redirectUrlOnCompletion = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Bundle, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* repeated verily_me.web.bundle.bff.api.v1.BundleStep steps = 2; */
        for (let i = 0; i < message.steps.length; i++)
            BundleStep.internalBinaryWrite(message.steps[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string bundle_plan_id = 3; */
        if (message.bundlePlanId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.bundlePlanId);
        /* string bundle_plan_name = 4; */
        if (message.bundlePlanName !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.bundlePlanName);
        /* string redirect_url_on_completion = 5; */
        if (message.redirectUrlOnCompletion !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.redirectUrlOnCompletion);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message verily_me.web.bundle.bff.api.v1.Bundle
 */
export const Bundle = new Bundle$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BundleStep$Type extends MessageType<BundleStep> {
    constructor() {
        super("verily_me.web.bundle.bff.api.v1.BundleStep", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "type", kind: "enum", T: () => ["verily_me.web.bundle.bff.api.v1.StepType", StepType, "STEP_TYPE_"] },
            { no: 3, name: "status", kind: "enum", T: () => ["verily_me.web.bundle.bff.api.v1.StepStatus", StepStatus, "STEP_STATUS_"] },
            { no: 4, name: "last_submittable", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "action_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "info_static_content", kind: "message", oneof: "staticContent", T: () => InfoStaticContent }
        ]);
    }
    create(value?: PartialMessage<BundleStep>): BundleStep {
        const message = { name: "", type: 0, status: 0, lastSubmittable: false, actionName: "", staticContent: { oneofKind: undefined } };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BundleStep>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BundleStep): BundleStep {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* verily_me.web.bundle.bff.api.v1.StepType type */ 2:
                    message.type = reader.int32();
                    break;
                case /* verily_me.web.bundle.bff.api.v1.StepStatus status */ 3:
                    message.status = reader.int32();
                    break;
                case /* bool last_submittable */ 4:
                    message.lastSubmittable = reader.bool();
                    break;
                case /* string action_name */ 5:
                    message.actionName = reader.string();
                    break;
                case /* verily_me.web.bundle.bff.api.v1.InfoStaticContent info_static_content */ 6:
                    message.staticContent = {
                        oneofKind: "infoStaticContent",
                        infoStaticContent: InfoStaticContent.internalBinaryRead(reader, reader.uint32(), options, (message.staticContent as any).infoStaticContent)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BundleStep, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* verily_me.web.bundle.bff.api.v1.StepType type = 2; */
        if (message.type !== 0)
            writer.tag(2, WireType.Varint).int32(message.type);
        /* verily_me.web.bundle.bff.api.v1.StepStatus status = 3; */
        if (message.status !== 0)
            writer.tag(3, WireType.Varint).int32(message.status);
        /* bool last_submittable = 4; */
        if (message.lastSubmittable !== false)
            writer.tag(4, WireType.Varint).bool(message.lastSubmittable);
        /* string action_name = 5; */
        if (message.actionName !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.actionName);
        /* verily_me.web.bundle.bff.api.v1.InfoStaticContent info_static_content = 6; */
        if (message.staticContent.oneofKind === "infoStaticContent")
            InfoStaticContent.internalBinaryWrite(message.staticContent.infoStaticContent, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message verily_me.web.bundle.bff.api.v1.BundleStep
 */
export const BundleStep = new BundleStep$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InfoStaticContent$Type extends MessageType<InfoStaticContent> {
    constructor() {
        super("verily_me.web.bundle.bff.api.v1.InfoStaticContent", [
            { no: 1, name: "next_button_text", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "previous_button_text", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "close_button_text", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "previous_button_state", kind: "enum", T: () => ["verily_me.web.bundle.bff.api.v1.ButtonState", ButtonState] }
        ]);
    }
    create(value?: PartialMessage<InfoStaticContent>): InfoStaticContent {
        const message = { nextButtonText: "", previousButtonText: "", closeButtonText: "", previousButtonState: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<InfoStaticContent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InfoStaticContent): InfoStaticContent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string next_button_text */ 1:
                    message.nextButtonText = reader.string();
                    break;
                case /* string previous_button_text */ 2:
                    message.previousButtonText = reader.string();
                    break;
                case /* string close_button_text */ 3:
                    message.closeButtonText = reader.string();
                    break;
                case /* verily_me.web.bundle.bff.api.v1.ButtonState previous_button_state */ 4:
                    message.previousButtonState = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: InfoStaticContent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string next_button_text = 1; */
        if (message.nextButtonText !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.nextButtonText);
        /* string previous_button_text = 2; */
        if (message.previousButtonText !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.previousButtonText);
        /* string close_button_text = 3; */
        if (message.closeButtonText !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.closeButtonText);
        /* verily_me.web.bundle.bff.api.v1.ButtonState previous_button_state = 4; */
        if (message.previousButtonState !== 0)
            writer.tag(4, WireType.Varint).int32(message.previousButtonState);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message verily_me.web.bundle.bff.api.v1.InfoStaticContent
 */
export const InfoStaticContent = new InfoStaticContent$Type();
/**
 * @generated ServiceType for protobuf service verily_me.web.bundle.bff.api.v1.BundleService
 */
export const BundleService = new ServiceType("verily_me.web.bundle.bff.api.v1.BundleService", [
    { name: "BatchGetBundles", options: {}, I: BatchGetBundlesRequest, O: BatchGetBundlesResponse },
    { name: "GetBundle", options: {}, I: GetBundleRequest, O: Bundle },
    { name: "ListBundles", options: {}, I: ListBundlesRequest, O: ListBundlesResponse },
    { name: "UpdateBundleSteps", options: {}, I: UpdateBundleStepsRequest, O: UpdateBundleStepsResponse }
]);
